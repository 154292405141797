import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  ClientContactList,
  ClientManagementList,
  ClientWebsiteList,
  CMSWebsiteList,
  DeleteClient,
  DeleteWebsite,
  DeleteWebsiteFile,
  ResendInviteContactClient,
  SubmitClientContactForm,
  SubmitClientManagementForm,
  SubmitWebsiteForm,
} from '../actions/client-management.action';
import { HttpService } from '@app/core/http';
import { ToastrService } from 'ngx-toastr';
import { tap } from 'rxjs';
import { patch, removeItem, updateItem } from '@ngxs/store/operators';

interface IClientManagementStateModel {
  clientList: IClientManagementList[];
  clientListCount: number;
  clientContactList: IClientContactlist[];
  clientContactListCount: number;
  clientWebsiteList: IWebsiteList[];
  clientWebsiteListCount: number;
  cmsList: ICMSList[];
  clientProjectList: IWebsiteList[];
  carePlanList: IWebsiteList[];
  // technologyList : ITechnologyListForWebsite[]
}

State<IClientManagementStateModel>({
  name: 'ClientManagementState',
  defaults: {
    clientList: [],
    clientListCount: 0,
    clientContactList: [],
    clientContactListCount: 0,
    clientWebsiteList: [],
    clientWebsiteListCount: 0,
    cmsList: [],
    clientProjectList: [],
    carePlanList: [],
    // technologyList : []
  },
});

@Injectable()
export class ClientManagementState {
  constructor(private _http: HttpService, private _toastr: ToastrService) {}

  @Selector()
  static clientList(state: IClientManagementStateModel) {
    return state.clientList;
  }

  @Selector()
  static clientListCount(state: IClientManagementStateModel) {
    return state.clientListCount;
  }

  @Selector()
  static clientContactList(state: IClientManagementStateModel) {
    return state.clientContactList;
  }

  @Selector()
  static clientContactListCount(state: IClientManagementStateModel) {
    return state.clientContactListCount;
  }

  @Selector()
  static clientWebsiteList(state: IClientManagementStateModel) {
    return state.clientWebsiteList;
  }

  @Selector()
  static clientWebsiteListCount(state: IClientManagementStateModel) {
    return state.clientWebsiteListCount;
  }

   @Selector()
  static websiteListCount(state: IClientManagementStateModel) {
    return state.clientWebsiteList.filter((data) => data.project_type_id !== 1).length;
  }

   @Selector()
  static carePlanListCount(state: IClientManagementStateModel) {
    return state.clientWebsiteList.filter((data) => data.project_type_id === 1).length;
  }

  @Selector()
  static CMSWebsiteList(state: IClientManagementStateModel) {
    return state.cmsList;
  }

  @Selector()
  static clientProjectList(state: IClientManagementStateModel) {
    return state.clientWebsiteList.filter((data) => data.project_type_id !== 1);
  }
  @Selector()
  static carePlanList(state: IClientManagementStateModel) {
    return state.clientWebsiteList.filter((data) => data.project_type_id === 1);
  }

  // @Selector()
  // static technologyList(state: IClientManagementStateModel) {
  //   return state.technologyList;
  // }

  @Action(ClientManagementList)
  ClientManagementList(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: ClientManagementList
  ) {
    return this._http.post('client-management/client-list', param).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          clientList: resultData.clients,
          clientListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(SubmitClientManagementForm)
  SubmitClientManagementForm(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: SubmitClientManagementForm
  ) {
    return this._http.post('client-management/add-edit-client', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {});
      })
    );
  }

  @Action(DeleteClient)
  DeleteClient(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: DeleteClient
  ) {
    return this._http.post('client-management/client-delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            clientList: removeItem<IClientManagementList>(
              (item) => item.client_id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(ClientContactList)
  ClientContactList(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: ClientContactList
  ) {
    return this._http.post('client-management/contact-list', param).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          clientContactList: resultData.contacts,
          clientContactListCount: resultData.total_rows,
        });
      })
    );
  }

  @Action(SubmitClientContactForm)
  SubmitClientContactForm(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: SubmitClientContactForm
  ) {
    return this._http.post('client-management/add-edit-contact', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {});
      })
    );
  }

  @Action(ClientWebsiteList)
  ClientWebsiteList(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: ClientWebsiteList
  ) {
    return this._http.post('website/list', param).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.response.dataset;
        // const filterProjectList = resultData.websites.filter((data:any)=>data.project_type_id !==1)
        // const filterCarePlanList = resultData.websites.filter((data:any)=>data.project_type_id ===1)

        ctx.patchState({
          clientWebsiteList: resultData.websites,
          clientWebsiteListCount: resultData.total_rows,
          // clientProjectList : filterProjectList,
          // carePlanList : filterCarePlanList
        });
      })
    );
  }

  @Action(CMSWebsiteList)
  CMSWebsiteList(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: CMSWebsiteList
  ) {
    return this._http.post('website/cms-list', param).pipe(
      tap((apiResult: any) => {
        const resultData = apiResult.response.dataset;

        ctx.patchState({
          cmsList: resultData.cms,
        });
      })
    );
  }

  // @Action(TechnologyWebsiteList)
  // TechnologyWebsiteList(
  //   ctx: StateContext<IClientManagementStateModel>,
  //   { param }: TechnologyWebsiteList
  // ) {
  //   return this._http.post('website/technology-list', param).pipe(
  //     tap((apiResult: any) => {
  //       const resultData = apiResult.response.dataset;

  //       ctx.patchState({
  //         technologyList: resultData.technologies,
  //       });
  //     })
  //   );
  // }

  @Action(SubmitWebsiteForm)
  SubmitWebsiteForm(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: SubmitWebsiteForm
  ) {
    return this._http.post('website/add-edit', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {});
      })
    );
  }

  @Action(DeleteWebsite)
  DeleteWebsite(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: DeleteWebsite
  ) {
    return this._http.post('website/delete', param).pipe(
      tap((apiResult) => {
        ctx.setState(
          patch({
            clientWebsiteList: removeItem<IWebsiteList>(
              (item) => item.website_id === param.id
            ),
          })
        );
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }

  @Action(DeleteWebsiteFile)
  DeleteWebsiteFile(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: DeleteWebsiteFile
  ) {
    return this._http.post('file/delete', param).pipe(
      tap((apiResult) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
        if (param.opt_for === 'website_logo') {
          ctx.setState(
            patch<IClientManagementStateModel>({
              clientWebsiteList: updateItem<IWebsiteList>(
                (item) => item.website_id === param.id,
                patch({ website_logo: '' })
              ),
            })
          );
        } else {
          ctx.setState(
            patch<IClientManagementStateModel>({
              clientWebsiteList: updateItem<IWebsiteList>(
                (item) => item.website_id === param.id,
                patch({ website_banner: '' })
              ),
            })
          );
        }
      })
    );
  }
  @Action(ResendInviteContactClient)
  ResendInviteContactClient(
    ctx: StateContext<IClientManagementStateModel>,
    { param }: ResendInviteContactClient
  ) {
    return this._http.post('client-management/resend-inv', param).pipe(
      tap((apiResult: any) => {
        this._toastr.success(apiResult.response.status.msg, 'success', {
          closeButton: true,
          timeOut: 3000,
        });
      })
    );
  }
}
