export class ClientManagementList {
  static readonly type = '[ClientManagementList] Post';
  constructor(public param: {}) {}
}

export class SubmitClientManagementForm {
  static readonly type = '[SubmitClientManagementForm] Post';
  constructor(public param: ISubmitClientManagementParam) {}
}

export class DeleteClient {
  static readonly type = '[DeleteClient] Post';
  constructor(public param: { id: number }) {}
}
export class ClientContactList {
  static readonly type = '[ClientContactList] Post';
  constructor(public param: { client_id: number }) {}
}

export class SubmitClientContactForm {
  static readonly type = '[SubmitClientContactForm] Post';
  constructor(public param: IContactParams) {}
}

export class ClientWebsiteList {
  static readonly type = '[ClientWebsiteList] Post';
  constructor(public param: { client_id: number }) {}
}

export class CMSWebsiteList {
  static readonly type = '[CMSWebsiteList] Post';
  constructor(public param: {}) {}
}

export class SubmitWebsiteForm {
  static readonly type = '[SubmitWebsiteForm] Post';
  constructor(public param: FormData) {}
}

export class DeleteWebsite {
  static readonly type = '[DeleteWebsite] Post';
  constructor(public param: { id: number }) {}
}

export class DeleteWebsiteFile {
  static readonly type = '[DeleteWebsiteFile] Post';
  constructor(public param: IDeleteWebsiteFile) {}
}


export class ResendInviteContactClient {
  static readonly type = '[ResendInviteContactClient] Post';
  constructor(public param: IResendInviteParam) {}
}

// export class TechnologyWebsiteList {
//   static readonly type = '[TechnologyWebsiteList] Post';
//   constructor(public param: {}) {}
// }
